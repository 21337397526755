$colorMain: #d7af4d;

.imgLogo{
    height: 50px;
    width: 50px;
    margin-right: 25px;
}

.navBarCustom{
    height: 65px;
}

.nav-link{
    color:rgb(255, 255, 255);
    font-size: 1.075em;
    font-weight: 400;
    margin-right: 5px;
    transition: .3s ease-in-out;
    &:hover{
        color: $colorMain;
        transition: .3s ease-in-out;
    }
}

.dropdown-item{
    color:rgb(255, 255, 255);
    font-size: 1.075em;
    font-weight: 400;
    transition: .3s ease-in-out;
    &:hover{
        color: $colorMain;
        transition: .3s ease-in-out;
    }
}

.btnContactanos{
    border: 1px solid $colorMain !important;
    color: rgb(255, 255, 255) !important;
    font-size: 1.075em !important;
    font-weight: 400 !important;
    transition: .3s ease-in-out !important;
    &:hover{
       background-color: $colorMain !important;
       color: rgb(0, 0, 0) !important;
        transition: .3s ease-in-out !important;
    }
}

@media (max-width:1000px) {
    .navBarCustom{
        height: auto;
    }
}

@media (max-width:480px) {
    .navBarCustom{
        height: auto;
    }

    .dropdown-item{
        color:rgb(255, 255, 255);
        font-size: .85em;
        font-weight: 400;
        transition: .3s ease-in-out;
        &:hover{
            color: $colorMain;
            transition: .3s ease-in-out;
        }
    }
   
}