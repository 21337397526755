@keyframes shadowPulse {
    0% {
        filter: drop-shadow(2px 2px 3px rgba(255, 255, 255, 0.3));
    }

    50% {
        filter: drop-shadow(4px 4px 5px rgba(255, 255, 255, 0.6));
    }

    100% {
        filter: drop-shadow(2px 2px 3px rgba(255, 255, 255, 0.3));
    }
}

.callContainer{
    position: fixed;
    bottom: 15px;
    right: 10px;
    height: auto;
    z-index: 1000;
    .callImg{
        height: 70px;
        animation: shadowPulse 3s infinite;
    }
}

@media (max-width:480px) {
    .callContainer{
        position: fixed;
        bottom: 15px;
        right: 15px;
        height: auto;
        .callImg{
            height: 70px;
        }
    }
}