.formContainerMain {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: rgb(248, 249, 250);
  z-index: 1000;
}

.formContainer {
  position: relative;
  top: 10%;
  right: 0;
  width: 35%;
  border-radius: 20px;
  box-shadow: 3px 3px 15px rgb(131, 127, 127);
  background-color: white;

  .textTermns {
    color: grey;
    font-size: 0.8em;
  }

  .inputCustom {
    height: 45px;
  }
}

.linkAgent {
  text-decoration: none !important;
  color: white;
}

.btnAgent,
.btnWhats {
  width: 100%;
  border-radius: 25px;
  color: white;
  padding: 12px 0px;
}

.btnWhats {
  background-color: #25d366;
  &:hover {
    background-color: #2ee471;
  }
}

.btnWhats:disabled {
  background-color: #25d366;
  opacity: 1;
  border: none;
  color: white;
}

.btnAgent {
  background-color: #ff8700;
  &:hover {
    background-color: #ff9924;
  }
}

@media (max-width: 767px) {
  .formContainerMain {
    position: relative;
    height: auto;
    padding: 35px 20px !important;
  }
  .formContainer {
    position: relative;
    top: 10%;
    right: 0;
    width: 100%;
    border-radius: 20px;
    box-shadow: 3px 3px 15px rgb(131, 127, 127);
    background-color: white;
    padding: 25px !important;

    .textTermns {
      color: grey;
      font-size: 0.8em;
    }

    .inputCustom {
      height: 45px;
    }
  }
}
